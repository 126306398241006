import { Injectable } from '@angular/core';
import { AppLocalization } from '../models/app-localization.model';
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import { TypeHelper } from '../utils/type-helper.util';
import enMessages from "../../../assets/localization/en.json";
import enCustomMessages from "../../../assets/localization/en-custom.json";
import plMessages from "../../../assets/localization/pl.json";
import plCustomMessages from "../../../assets/localization/pl-custom.json";

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  private locales: Record<string, string> = {
    'en-US': 'English',
    'pl-PL': 'Polish'
  }

  public defaultLocale: string = 'pl-PL';

  constructor() {
    console.log('LocalizationService');
  }

  public setUserLocale(userPreferredLanguage: string) {

    const localeStorageKey = 'locale';
    let userLocale = this.defaultLocale;

    if (!TypeHelper.isNullOrEmpty(userPreferredLanguage) && this.localeExists(userPreferredLanguage)) {

      userLocale = userPreferredLanguage;
      localStorage.setItem(localeStorageKey, userLocale);

    } else {
      const storageLocale = localStorage.getItem(localeStorageKey) ?? '';

      if (!TypeHelper.isNullOrEmpty(storageLocale) && this.localeExists(storageLocale)) {
        userLocale = storageLocale as string;
      } else if (!TypeHelper.isNullOrEmpty(navigator.language) && this.localeExists(navigator.language)) {
        userLocale = navigator.language;
      }
    }

    switch(userLocale) {
      case 'en-US':
        loadMessages(enMessages);
        loadMessages(enCustomMessages);
      break;
      case 'pl-PL':
        loadMessages(plMessages);
        loadMessages(plCustomMessages);
      break;
    }
    
    locale(userLocale);
  }

  public localeExists(code: string): boolean {
    return this.locales.hasOwnProperty(code);
  }

  public getLocalizations(): AppLocalization[] {

    let result: AppLocalization[] = [];

    Object.entries(this.locales).forEach(([k, v]) => {
      result.push({
        code: k,
        name: formatMessage(v)
      });
    });

    return result;
  }
}
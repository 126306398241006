export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Profile',
    path: '/profile',
    icon: 'card'
  },
  {
    text: 'Users',
    path: '/users',
    icon: 'group'
  },
  {
    text: 'Contractors',
    path: '/contractors',
    icon: 'folder'
  },
  {
    text: 'Orders',
    path: '/orders',
    icon: 'cart'
  }
];

import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Login } from '../../models/login.model';
import { BaseComponent } from 'src/app/shared/components/base/base/base.component';
import { AuthService } from 'src/app/shared/services';
import { DeviceService } from 'src/app/core/services/device.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent {
  loading = false;
  form: Login;

  constructor(private authService: AuthService, private router: Router, private deviceService: DeviceService) 
  { 
    super();
    this.form = {
      userName: '',
      password: '',
      rememberMe: false,
      deviceId: '',
      deviceName: ''
    };
  }

  async onSubmit(e: Event) {
    e.preventDefault();

    try {
      this.loading = true;

      this.form.deviceId = this.deviceService.getDeviceId();
      this.form.deviceName = this.deviceService.getDeviceName();
      
      await this.authService.logInAsync(this.form);
    } 
    finally {
      this.loading = false;
    }
  }

  onCreateAccountClick = () => {
    this.router.navigate(['/create-account']);
  }
}
<form class="create-account-form" (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">

    <dxi-item dataField="email" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Email', mode: 'email' }">
      <dxi-validation-rule type="required" message="Email is required"></dxi-validation-rule>
      <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item dataField="password" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }">
      <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item dataField="confirmedPassword" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' }">
      <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
      <dxi-validation-rule type="custom" message="Passwords do not match" [validationCallback]="confirmPassword">
      </dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item>
      <div class='policy-info'>
        By creating an account, you agree to the <a routerLink="#">Terms of Service</a> and <a routerLink="#">Privacy
          Policy</a>
      </div>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'createAccountTemplate'">
      </dxo-button-options>
    </dxi-item>

    <dxi-item>
      <div class="login-link">
        Have an account? <a routerLink="/login">Sign In</a>
      </div>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'createAccountTemplate'">
      <div>
        <span class="dx-button-text">
          <ng-container *ngIf="loading; else notLoading">
            <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
          </ng-container>
  
          <ng-template #notLoading>Create a new account</ng-template>
        </span>
      </div>
    </ng-container>

  </dx-form>
</form>

import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base/base.component';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: [ './home.component.scss' ]
})

export class HomeComponent extends BaseComponent {
  
  constructor() {
    super();
  }
}

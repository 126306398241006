import { Injectable } from '@angular/core';
import { IResult, UAParser } from 'ua-parser-js';
@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  result: IResult;
  
  constructor() { 
    let parser = new UAParser();
    this.result = parser.getResult();
  }

  public getDeviceId(): string {
    let id = `${this.result.browser.name}${this.result.browser.version}${this.result.os.name}${this.result.os.version}${this.result.cpu.architecture}`;
    let base64 = btoa(id);

    return base64;
  }

  public getDeviceName(): string {
    let name = `${this.result.browser.name} ver. ${this.result.browser.version} on ${this.result.os.name} ver. ${this.result.os.version}`;

    return name;
  }
}

<ng-container *ngIf="isAuthenticated(); else unauthenticated">
  <app-side-nav-outer-toolbar title="{{appInfo.title}}">
    <router-outlet></router-outlet>
    <app-footer>
      Copyright © 2023-{{appInfo.currentYear}} {{appInfo.softmexName}}
    </app-footer>
  </app-side-nav-outer-toolbar>
</ng-container>

<ng-template #unauthenticated>
  <app-unauthenticated-content></app-unauthenticated-content>
</ng-template>

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ValidationCallbackData } from 'devextreme/ui/validation_rules';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { BaseComponent } from 'src/app/shared/components/base/base/base.component';
import { AuthService } from 'src/app/shared/services';
import { PasswordPolicy } from 'src/app/core/models/password-policy.model';
import { SetPassword } from '../../models/set-password.model';
import { NotifyHelper } from 'src/app/core/utils/notify-helper.util';


@Component({
  selector: 'app-set-passsword',
  templateUrl: './set-password.component.html'
})
export class SetPasswordComponent extends BaseComponent implements OnInit {
  loading = false;
  form: SetPassword;
  passwordPolicy: PasswordPolicy;
  requiredLengthValue: string = '';
  requiredUniqueCharsValue: string = '';

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { 
    super();
    this.form = {
      identity: '',
      password: '',
      confirmPassword: '',
      token: '',
    };

    this.passwordPolicy = {
      requireDigit: false,
      requireLowercase: false,
      requireUppercase: false,
      requireNonAlphanumeric: false,
      requiredLength: 0,
      requiredUniqueChars: 0
    }
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.form.identity = params.get('identity') || '';
      this.form.token = params.get('token') || '';
    });

    let passwordPolicyResult = await this.authService.getPasswordPolicyAsync();

    if(passwordPolicyResult.succeeded) {
      this.passwordPolicy = passwordPolicyResult.data as PasswordPolicy;
      this.requiredLengthValue = String(this.passwordPolicy.requiredLength);
      this.requiredUniqueCharsValue = String(this.passwordPolicy.requiredUniqueChars);
    }
  }

  async onSubmit(e: Event) {
    e.preventDefault();

    try {
      this.loading = true;

      let result = await this.authService.setPasswordAsync(this.form);

      if(result.succeeded) {
        NotifyHelper.notifySuccess('Password has been changed.')
        this.router.navigate(['/login']);  
      }
    }
    finally {
      this.loading = false;
    }
  }

  confirmPassword = (e: ValidationCallbackData) => {
    return e.value === this.form.password;
  }
}
import themes from 'devextreme/ui/themes';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import axios from 'axios';
import { Environment } from './environments/environment.development';
import { NotifyHelper } from './app/core/utils/notify-helper.util';
import { AppConstats } from './app/shared/utils/app.constants';


themes.initialized(() => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

axios.defaults.baseURL = Environment.apiEndpoint;

axios.interceptors.request.use(request => {

  let accessToken = localStorage.getItem(AppConstats.Jwt.AccessTokenKey) ?? '';
  request.headers.Authorization = 'Bearer ' + accessToken;

  return request;
});

axios.interceptors.response.use(response => {
  //TODO: Convert date from UTC
  return response;
}, error => {
  if (error.response) {
    //  console.log(error.response);
    switch (error.response.status) {
      case 500:
        NotifyHelper.notifyError(error.message);

        if (error.response.data) {
          console.log(error.response.data);
        }
        break;
      case 405:
        //TODO: Method Not Allowed -> Redirect to custom page
        break;
      case 404:
        //TODO: Not found -> Redirect to custom page
        break;
      case 403:
        //TODO: Forbidden -> Redirect to custom page
        break;
      case 401:
        //TODO: Unauthorized -> Logout and redirect to login page
        break;
      case 400:
        if (error.response.data && error.response.data.succeeded === false && error.response.data.messages.length > 0) {
          NotifyHelper.notifyError(error.response.data.messages[0].message, error.response.data.messages[0].properties);
        }
        else if (error.response.data && error.response.data.succeeded === false && error.response.data.messages.length == 0) {
          NotifyHelper.notifyError('The operation failed.');
        }
        else if (typeof error.response.data === 'string') {
          NotifyHelper.notifyError(error.response.data);
        }
        else {
          NotifyHelper.notifyError(error.message);
        }
        break;
    }
  } else {
    NotifyHelper.notifyError(error.message);
  }
  return Promise.reject(error)
})

console.log('main');
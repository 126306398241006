import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { NotifyHelper } from 'src/app/core/utils/notify-helper.util';
import { BaseComponent } from 'src/app/shared/components/base/base/base.component';
import { AuthService } from 'src/app/shared/services';

const notificationText = 'We\'ve sent a link to reset your password. Check your inbox.';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent {
  loading = false;
  formData: any = {};

  constructor(private authService: AuthService, private router: Router) {
    super();
  }

  async onSubmit(e: Event) {
    e.preventDefault();

    try {
      this.loading = true;
      const { email } = this.formData;

      let result = await this.authService.resetPasswordAsync(email);

      if(result.succeeded) {
        NotifyHelper.notifySuccess(result.messages[0].message)
        this.router.navigate(['/login']);  
      }
    }
    finally {
      this.loading = false;
    }
  }
}

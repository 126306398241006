import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { BaseComponent } from './shared/components/base/base/base.component';
import { AuthModule } from './modules/core/auth/auth.module';
import { LocalizationService } from './core/services/localization.service';

export function initializeApp(authService: AuthService, localizationService: LocalizationService) {
  return async () => {
    console.log('initializeApp');
    await authService.startTokenTimerAsync();

    let preferredLanguage = '';
    
    if (authService.loggedIn) {
      await authService.setIdentityAsync();

      let identity = authService.getIdentity();
      preferredLanguage = identity.preferredLanguage;
    }

    localizationService.setUserLocale(preferredLanguage);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent
  ],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    AuthModule
  ],
  providers: [
    ScreenService,
    AppInfoService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AuthService, LocalizationService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

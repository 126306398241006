import { Injectable } from '@angular/core';
import { Environment } from 'src/environments/environment.development';

@Injectable()
export class AppInfoService {
  constructor() {}

  public get title() {
    return Environment.appName;
  }

  public get softmexName() {
    return Environment.softmexName;
  }

  public get currentYear() {
    return new Date().getFullYear();
  }
}

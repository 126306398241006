import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { RouterModule } from '@angular/router';
import { DxFormModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoginComponent } from './components/login/login.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';


@NgModule({
  declarations: [
    CreateAccountComponent,
    ActivateAccountComponent,
    LoginComponent,
    ResetPasswordComponent,
    SetPasswordComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ]
})
export class AuthModule { 
  constructor() {
    console.log('AuthModule');
  }
}

export const AppConstats = {
  DefaultPath: "/",
  DateFormat: "yyyy-MM-dd",
  DataTimeFormat: "yyyy-MM-dd HH:mm",
  DateTimeUtcFormat: "yyyy-MM-ddTHH:mm:ssZ",
  Jwt: {
    "AccessTokenKey": "access_token",
    "RefreshTokenKey": "refresh_token"
  }
}

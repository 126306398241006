import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { AppConstats } from "../../shared/utils/app.constants";
import { IdentityUser } from 'src/app/core/models/identity-user.model';
import { jwtDecode } from "jwt-decode";
import { AuthToken } from '../models/auth-token.model';
import { ApiResult } from '../models/api-result.model';
import axios from 'axios';
import { TypeHelper } from '../utils/type-helper.util';
import { Login } from 'src/app/modules/core/auth/models/login.model';
import { SetPassword } from 'src/app/modules/core/auth/models/set-password.model';
import { ActivateAccount } from 'src/app/modules/core/auth/models/activate-account.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private identity: IdentityUser;
  private lastAuthenticatedPath: string = AppConstats.DefaultPath;
  private tokenTimer: any;

  constructor(private router: Router, private httpClient: HttpClient) {
    console.log('AuthService');
    this.identity = {
      publicId: '',
      userName: '',
      isActive: false,
      isAdmin: false,
      firstName: '',
      lastName: '',
      email: '',
      preferredLanguage: ''
    };
  }

  public async activateAccountAsync(data: ActivateAccount): Promise<ApiResult> {
    return (await axios.post<ApiResult>("/auth/activate-account", data)).data;
  }

  public async logInAsync(data: Login) {

    try {
      this.clearAll();

      let result = (await axios.post<ApiResult>("/auth/login", data)).data;

      if (result.succeeded) {

        let token = result.data as AuthToken;
        
        if (token.foreChangePassword === true) {
          window.location.href = token.changePasswordLink;
          return;
        }

        this.setTokenStorage(token);

        await this.startTokenTimerAsync();
        await this.setIdentityAsync();

        await this.router.navigate(['/']);
      }
    } catch (error) {
      console.log(error);
      await this.clearAllAndRedirectToLoginAsync();
    }
  }

  public async logOutAsync() {

    try {
      let authToken = this.getAuthToken();

      await axios.post<ApiResult>("/auth/logout", authToken);

      this.clearAll();
      this.stopTokenTimer();

      await this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
      await this.clearAllAndRedirectToLoginAsync();
    }
  }

  public async getPasswordPolicyAsync(): Promise<ApiResult> {
    return (await axios.get<ApiResult>("/auth/password-policy")).data;
  }

  public async resetPasswordAsync(email: string): Promise<ApiResult> {
    return (await axios.post<ApiResult>("/auth/reset-password", { email })).data;
  }

  public async setPasswordAsync(model: SetPassword): Promise<ApiResult> {
    return (await axios.post<ApiResult>("/auth/set-password", model)).data;
  }

  private getAuthToken(): AuthToken | null {

    const accessToken = localStorage.getItem(AppConstats.Jwt.AccessTokenKey);

    if (TypeHelper.isNullOrEmpty(accessToken)) {
      console.log('Cannot preparing auth token model.');
      return null;
    }

    const refreshToken = localStorage.getItem(AppConstats.Jwt.RefreshTokenKey);

    if (TypeHelper.isNullOrEmpty(refreshToken)) {
      console.log('Cannot preparing auth token model.');
      return null;
    }

    let result: AuthToken = {
      accessToken: accessToken ?? '',
      refreshToken: refreshToken ?? '',
      foreChangePassword: false,
      changePasswordLink: ''
    };

    return result;
  }

  public setTokenStorage(token: AuthToken) {
    localStorage.setItem(AppConstats.Jwt.AccessTokenKey, token.accessToken);
    localStorage.setItem(AppConstats.Jwt.RefreshTokenKey, token.refreshToken);
  }

  public clearTokenStorage() {
    localStorage.removeItem(AppConstats.Jwt.AccessTokenKey);
    localStorage.removeItem(AppConstats.Jwt.RefreshTokenKey);
  }

  private clearAll() {
    this.clearTokenStorage();
    this.identity = {
      publicId: '',
      userName: '',
      isActive: false,
      isAdmin: false,
      firstName: '',
      lastName: '',
      email: '',
      preferredLanguage: ''
    };
  }

  private async clearAllAndRedirectToLoginAsync() {
    this.clearAll();
    await this.router.navigate(['/login']);
  }

  public async startTokenTimerAsync() {
    const accessToken = localStorage.getItem(AppConstats.Jwt.AccessTokenKey) ?? '';

    if (!TypeHelper.isNullOrEmpty(accessToken)) {

      try {
        const decoded = jwtDecode(accessToken);
        let leftTime = (decoded.exp ?? 0) * 1000 - (new Date()).getTime();

        leftTime -= (30 * 1000);

        if (leftTime > 0) {
          this.tokenTimer = setTimeout(async () => {
            await this.refreshTokenAsync();
          }, leftTime);
        }
      } catch (error) {
        console.log(error);
        await this.clearAllAndRedirectToLoginAsync();
      }
    }
  }

  public stopTokenTimer() {
    this.tokenTimer = null;
  }

  private tokenExpired(token: string): boolean {
    if (TypeHelper.isNullOrEmpty(token)) {
      return true;
    }

    try {
      const decoded = jwtDecode(token);

      const date = new Date(0);
      date.setUTCSeconds(decoded.exp ?? 0);

      const expired = date.valueOf() < new Date().valueOf();

      return expired;
    } catch {
      return true;
    }
  }

  private async refreshTokenAsync(): Promise<void> {

    let authToken = this.getAuthToken();

    try {
      let result = (await axios.post<ApiResult>("/auth/token/refresh", authToken)).data;

      if (result.succeeded) {

        let token = result.data as AuthToken;

        this.setTokenStorage(token);
        await this.startTokenTimerAsync();
      } else {
        await this.clearAllAndRedirectToLoginAsync();
      }
    } catch (error) {
      console.log(error);
      await this.clearAllAndRedirectToLoginAsync();
    }
  }

  public getIdentity(): IdentityUser {
    return this.identity;
  }

  public async setIdentityAsync() {
    try {
      let identityResult = (await axios.get<ApiResult>("/auth/identity")).data;
      this.identity = identityResult.data as IdentityUser;
    } catch (error) {
      console.log(error);
      await this.clearAllAndRedirectToLoginAsync();
    }
  }

  public getAuthHeader() {
    let accessToken = localStorage.getItem(AppConstats.Jwt.AccessTokenKey) ?? '';

    return {
      'Authorization': 'Bearer ' + accessToken
    };
  }

  public get loggedIn(): boolean {
    const token = localStorage.getItem(AppConstats.Jwt.AccessTokenKey) ?? '';
    return !this.tokenExpired(token);
  }

  public setLastAuthenticatedPath(value: string) {
    this.lastAuthenticatedPath = value;
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    console.log('canActivate');
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login',
      'reset-password',
      'create-account',
      'set-password/:identity/:token',
      'activate-account/:identity/:token'
    ].includes(route.routeConfig?.path || AppConstats.DefaultPath);

    if (isLoggedIn && isAuthForm) {
      this.authService.setLastAuthenticatedPath(AppConstats.DefaultPath);
      this.router.navigate([AppConstats.DefaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login']);
    }

    if (isLoggedIn) {
      this.authService.setLastAuthenticatedPath(route.routeConfig?.path || AppConstats.DefaultPath);
    }

    return isLoggedIn || isAuthForm;
  }
}

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DxFormModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { BaseComponent } from 'src/app/shared/components/base/base/base.component';
import { AuthService } from 'src/app/shared/services';
import { ActivateAccount } from '../../models/activate-account.model';
import { NotifyHelper } from 'src/app/core/utils/notify-helper.util';
import { DeviceService } from 'src/app/core/services/device.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html'
})
export class ActivateAccountComponent extends BaseComponent implements OnInit {
  data: ActivateAccount;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private deviceService: DeviceService) {
    super();
    console.log('ActivateAccount');
    this.data = {
      identity: '',
      token: '',
      deviceId: '',
      deviceName: ''
    };
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.data.identity = params.get('identity') || '';
      this.data.token = params.get('token') || '';
    });

    this.data.deviceId = this.deviceService.getDeviceId();
    this.data.deviceName = this.deviceService.getDeviceName();
    
    let result = await this.authService.activateAccountAsync(this.data);

    if(result.succeeded) {
      NotifyHelper.notifySuccess("Your account is activated.");
    }

    await this.router.navigate(['/login']);
  }

}

import { formatMessage } from "devextreme/localization";
import { TypeHelper } from "./type-helper.util";

export class LocalizationHelper {
    
    static translate(message: string, ...parameters: string[]) : string {
        let translatedMessage = formatMessage(message, ...parameters);
        
        if (TypeHelper.isNullOrEmpty(translatedMessage)) {
            translatedMessage = message;
        }

        return translatedMessage;
    }
}

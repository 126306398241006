import notify from 'devextreme/ui/notify';
import { LocalizationHelper } from './localization-helper.util';

export class NotifyHelper {
    static displayTime: number = 3000;

    static notifyInfo(message: string, parameters: string[] = []) {
        notify(LocalizationHelper.translate(message, ...parameters), "info", this.displayTime);
    }

    static notifySuccess(message: string, parameters: string[] = []) {
        notify(LocalizationHelper.translate(message, ...parameters), "success", this.displayTime);
    }

    static notifyWarning(message: string, parameters: string[] = []) {
        notify(LocalizationHelper.translate(message, ...parameters), "warning", this.displayTime);
    }

    static notifyError(message: string, parameters: string[] = []) {
        notify(LocalizationHelper.translate(message, ...parameters), "error", this.displayTime);
    }
}

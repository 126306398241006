<form class="reset-password-form" (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">

    <dxi-item dataField="email" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: formatMessage('E-mail'), mode: 'email' }">
      <dxi-validation-rule type="required" [message]="formatMessage('E-mail is required')"></dxi-validation-rule>
      <dxi-validation-rule type="email" [message]="formatMessage('E-mail is invalid')"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'resetPasswordTemplate'"
        [elementAttr]="{ class: 'submit-button' }">
      </dxo-button-options>
    </dxi-item>
    <dxi-item>
      <div class="login-link">
        {{formatMessage('Return to')}} <a routerLink="/login">{{formatMessage('Sign In')}}</a>
      </div>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'resetPasswordTemplate'">
      <div>
        <span class="dx-button-text">
          <ng-container *ngIf="loading; else notLoading">
            <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
          </ng-container>
          <ng-template #notLoading>{{formatMessage('Send link')}}</ng-template>
        </span>
      </div>>
    </ng-container>

  </dx-form>
</form>

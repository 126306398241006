import { Component } from '@angular/core';
import { formatMessage } from "devextreme/localization";
import {AppConstats} from "../../../utils/app.constants";

@Component({
  selector: 'app-base',
  template: ``,
  styleUrls: ['./base.component.scss']
})
export class BaseComponent {

  dateFormat: string = AppConstats.DateFormat;
  dateTimeFormat: string = AppConstats.DataTimeFormat;
  formatMessage = formatMessage;

  colCountByScreen = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4
  };
}

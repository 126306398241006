import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { DxDataGridModule, DxFormModule, DxButtonModule, DxBoxModule, DxAccordionModule } from 'devextreme-angular';
import { LoginComponent } from './modules/core/auth/components/login/login.component';
import { ResetPasswordComponent } from './modules/core/auth/components/reset-password/reset-password.component';
import { SetPasswordComponent } from './modules/core/auth/components/set-password/set-password.component';
import { ActivateAccountComponent } from './modules/core/auth/components/activate-account/activate-account.component';

const routes: Routes = [
  {
    path: 'users',
    loadChildren: () => import('./modules/core//users/users.module').then(m => m.UsersModule),
  },
  {
    path: "contractors",
    loadChildren: () => import('./modules/system/contractors/contractors.module').then(m => m.ContractorsModule),
  },
  {
    path: "orders",
    loadChildren: () => import('./modules/system/orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/core/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'set-password/:identity/:token',
    component: SetPasswordComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'activate-account/:identity/:token',
    component: ActivateAccountComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), DxDataGridModule, DxFormModule, DxButtonModule, DxBoxModule, DxAccordionModule ],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [
    HomeComponent
  ]
})
export class AppRoutingModule { }
